@import "./theme.less";

.login-wrap {
    color: @login-page-color;
    margin: auto;
    max-width: 525px;
    min-height: 670px;
    position: relative;
    width: 100%;
}

.login-form .group {
    margin-bottom: 30px;
}

.login-title {
    font-size: 22px;
}

.login-form {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 50px 50px 10px;
  border: 1px solid #ced2d7;
  border-radius: 8px;

  .auth {
    position: relative;
  }

  .auth-logo {
    text-align: center;
    margin-bottom: 25px;

    img {
      max-width: 100%;
      max-height: 70px;
      height: auto;
    }
  }

  .btn.btn-primary {
    background-color: @primary-color;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
  }

  input {
    width: 100%;
    display: block;
    border: 1px solid #e0e0e0;
    padding: 15px 10px;
    background: transparent;
    border-radius: 8px;
    transition: .3s;

    &:focus {
      outline: none;
      border: 1px solid @primary-color;
    }

    &.inputHasValue {
      & + label {
        top: -10px;
        font-size: 14px;
      }
    }
  }

  label {
    color:#999;
    font-size:15px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:10px;
    top:15px;
    padding: 0 5px;
    background-color: #fff;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
  }

  input:focus,
  input:valid &~label {
    &~label {
      top: -10px;
      font-size: 14px;
      color: @primary-color;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);

    &~label {
      top: -10px;
      font-size: 14px;
    }
  }

  .show-password {
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #666666;
  }

  a {
    color: #3555d9;

    &:hover {
      text-decoration: none;
    }
  }
}

.login-button:hover:enabled{
    text-decoration: underline;
}

.login-button {
    background: none !important;
    color: inherit;
    border: none;
    font-weight: bold !important;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
    float: right;
}

.logout-group {
    display: flex;
    height: 100%;
    width: 25%;
    float: right;
    color: white;
    padding: 35px;
}

.logout-group span {
    padding-right: 15px;
}

.logout-group a {
    float: right;
}

.error-message {
    color: @error-color;
}
.success-message {
    color: #447e2c;
}
