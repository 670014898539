.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}
.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}
.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}
.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}
.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}
.body-content {
  font-family: "Nunito", sans-serif !important;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
#app {
  height: 100%;
  width: 100%;
}
#app > div:first-child {
  height: 100%;
  width: 100%;
}
.svg-icon {
  max-width: 100%;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  height: 100%;
  fill: white;
  overflow: visible !important;
  padding-bottom: 0;
  display: block;
  padding-left: 15px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  /* -webkit-transition: all 0.5s ease-in-out; */
}
.logo {
  width: 33%;
  display: inline-block;
  height: 100%;
  position: relative;
  padding-left: 40px;
}
.logo img {
  max-height: 70px;
  width: auto;
}
.logo li {
  height: auto !important;
  margin: 0;
  font-size: 16px;
  width: 100%;
}
.logo a {
  display: flex;
  align-items: center;
  height: 100%;
}
@media all and (max-width: 1200px) {
  .logo {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-left: 0;
  }
  .logo a {
    justify-content: center;
  }
}
.customer-info {
  color: #fff;
  width: 33%;
  display: inline-block;
  text-align: center;
}
@media all and (max-width: 1200px) {
  .customer-info {
    width: 50%;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 800px) {
  .customer-info {
    width: 100%;
  }
}
.navbar {
  height: 10%;
  background-color: #1F3280 !important;
  border-color: #1F3280 !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 1200px) {
  .navbar {
    height: auto;
  }
}
.logout-group-wrap {
  float: none;
  width: 33%;
  display: inline-block;
  padding-right: 35px;
  color: #fff;
  text-align: right;
}
.logout-group-wrap a {
  color: #fff;
  padding: 5px 10px;
}
.logout-group-wrap a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #475797;
  border-radius: 4px;
}
.logout-group-wrap #profile-dropdown {
  background: transparent;
  color: #fff;
  border: none;
}
.logout-group-wrap #profile-dropdown:hover {
  background: transparent;
  color: #fff;
  border: none;
}
.logout-group-wrap .open > #profile-dropdown {
  background: transparent !important;
  color: #fff !important;
  border: none;
  box-shadow: none;
}
.logout-group-wrap .open > #profile-dropdown:hover {
  background: transparent;
  color: #fff;
  border: none;
}
.logout-group-wrap a {
  float: none;
}
.logout-group-wrap .dropdown-menu li {
  text-align: center;
  display: block;
}
.logout-group-wrap .dropdown-menu li:hover {
  background-color: #ccc;
}
.logout-group-wrap .dropdown-menu li a {
  color: #2e343a;
  text-decoration: none;
  display: block;
  padding: 5px;
}
.logout-group-wrap .dropdown-menu li a:hover {
  background: transparent;
}
.logout-group-wrap .dropdown-menu li a svg {
  margin-right: 5px;
}
.logout-group-wrap span {
  padding-right: 0;
}
.logout-group-wrap .app-info-btn {
  padding: 7px 9px 5px;
}
.logout-group-wrap .app-info-btn:hover {
  color: #fff;
  text-decoration: none;
  background-color: #475797;
  border-radius: 4px;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  .logout-group-wrap {
    width: 50%;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 800px) {
  .logout-group-wrap {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
}
p {
  margin-bottom: 0px !important;
  text-align: center;
}
body {
  height: 100vh;
}
body {
  min-height: 100vh;
}
.body-content {
  min-height: 85%;
}
#footer {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 5%;
  color: white;
  background-color: #1F3280;
}
.navbar-fixed-bottom {
  position: relative !important;
}
.icon-button:hover {
  cursor: pointer;
}
.status {
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
}
.json-field {
  min-height: 200px;
  resize: vertical;
}
svg.fa-spinner {
  width: 0.5em !important;
  height: 0.5em;
  display: inline-flex;
  align-self: center;
  margin-right: 5px;
}
svg.fa-spinner.large {
  display: block;
  margin: auto;
  width: 2em !important;
  height: 2em;
  align-self: center;
  justify-content: center;
}
.flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Select-menu-outer {
  z-index: 1000;
  position: relative;
}
.container-fluid {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.sortable {
  pointer-events: none;
}
.sortable-carret {
  margin-left: 3px;
  pointer-events: auto;
}
.sortable-carret:hover {
  cursor: pointer;
}
.filter {
  pointer-events: auto;
}
.react-bootstrap-table table.table > thead > tr > th {
  vertical-align: middle;
  color: #666666;
}
.react-bootstrap-table table.table > thead > tr > th .table-with-actions:last-child {
  vertical-align: bottom;
}
.react-bootstrap-table table.table > thead > tr > th label {
  width: 100%;
  margin-bottom: 0;
}
.react-bootstrap-table table.table > thead > tr > th select {
  color: #666666;
  font-weight: normal;
}
.react-bootstrap-table table.table > tbody > tr > td {
  vertical-align: middle;
}
.react-bootstrap-table-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.react-bootstrap-table-pagination div:first-child {
  width: 50%;
  min-height: 65px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.react-bootstrap-table-pagination > div:nth-child(2) {
  min-height: 65px;
  display: flex;
  margin-left: -30px;
  flex-flow: column;
}
.pagination {
  margin: 0 !important;
}
.react-bs-table-sizePerPage-dropdown.dropdown {
  width: 100%;
}
.react-bootstrap-table-pagination-list {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pagination-loader {
  display: inline-flex;
  position: absolute !important;
  float: left;
}
.pagination-loader svg {
  left: 90%;
  position: relative;
}
.flex-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.additional-buttons .btn-default {
  color: #304dc5;
}
.placeholder {
  opacity: 0.7;
  font-size: 8pt;
}
.rows-per-row-select {
  display: inline-block !important;
  max-width: 70px;
  margin-left: 10px;
}
.d2h-file-header {
  visibility: collapse;
  height: 0px;
}
.d2h-diff-table {
  width: inherit !important;
}
.rows-per-row-select {
  display: inline-block !important;
  max-width: 70px;
  margin-left: 10px;
}
.setting-value {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  width: 100%;
  align-items: center;
}
.back-arrow {
  width: 30px !important;
  height: 30px;
  margin-left: 30px;
  position: relative;
}
.arrow {
  color: #000;
  cursor: pointer;
  display: block;
  align-items: flex-start;
  text-transform: uppercase;
  position: relative;
  transition: transform 0.3s;
}
.arrow:before {
  content: '';
  display: block;
  border: solid #000;
  border-width: 2px 2px 0 0;
  position: absolute;
  top: 8px;
  width: 10px;
  height: 10px;
}
.arrow.arrow--left:before {
  transform: rotate(-135deg);
  right: 0;
  transition: right 0.3s 0.2s;
}
.arrow:hover {
  transform: none;
}
.link:hover {
  cursor: pointer;
}
.img-list-item * {
  margin-left: 5px;
}
.img-list-item {
  background: transparent;
}
.img-list-item:hover {
  cursor: pointer;
  background: #4a91cf;
}
.img-list-item.active {
  background: #699dca;
}
.img-list-item.active:hover {
  cursor: pointer;
  background: #4a91cf;
}
.file-list {
  color: black !important;
  text-align: right;
}
.img-preview {
  max-width: 120px;
  max-height: 120px;
}
.image-list {
  max-height: 400px;
  overflow-y: auto;
}
.bold {
  font-weight: bold;
}
/* Batch Modal customization */
.new-group-fields {
  margin-bottom: 20px;
}
.new-group-fields input,
.new-group-fields textarea {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 5px;
}
.helper-wrap {
  position: absolute;
  right: 30px;
  bottom: 0;
  text-align: right;
}
.helper {
  font-size: 12px;
  color: #666666;
  display: block;
  margin: -6px 0 6px;
}
.fields-underline {
  height: 1px;
  border-bottom: 1px solid #9e9e9e;
  width: calc(100% - 60px);
  float: left;
  margin: 0 30px 20px;
}
.modal-content {
  font-family: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system, sans-serif;
}
.modal-content .modal-header {
  padding: 15px 30px;
  border-bottom: 0;
}
.modal-content .modal-header h4 {
  font-size: 30px;
}
.modal-content .modal-footer {
  border-top: 0;
  padding: 20px 30px 30px;
}
.modal-content .modal-footer .btn-primary {
  background-color: #304dc5;
  color: #fff;
  border: 1px solid #304dc5;
  width: 38%;
  padding: 10px 20px;
  font-size: 18px;
}
.modal-content .modal-footer .btn-primary:hover {
  background-color: #1F3280;
}
.modal-content .modal-footer .btn-default {
  border: 1px solid #304dc5;
  color: #304dc5;
  width: 38%;
  padding: 10px 20px;
  font-size: 18px;
}
.modal-content .modal-footer .btn-success {
  width: 38%;
  padding: 10px 20px;
  font-size: 18px;
}
.modal-content .modal-footer .btn-danger {
  padding: 10px 20px;
  font-size: 18px;
}
.modal-content h4 {
  color: #000;
}
.modal-content h5 {
  font-size: 15px;
}
.modal-content .css-bgvzuu-indicatorSeparator {
  display: none;
}
.modal-content ::-webkit-input-placeholder {
  /* Edge */
  color: #666666;
}
.modal-content :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #666666;
}
.modal-content ::placeholder {
  color: #666666;
}
.modal-content .css-es53b3-multiValue {
  padding: 5px;
  border-radius: 14px;
  background-color: #f2f2f2;
}
.modal-content .css-16grloc {
  background: #616161;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.action-btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #1F3280;
  border-radius: 4px;
  box-sizing: border-box;
  transition: 0.3s;
  cursor: pointer;
}
.action-btn:disabled {
  background-color: #f2f2f2;
  color: #ccc;
  border-color: #ccc;
}
.action-btn.edit-icon:hover {
  color: green;
  border-color: green;
}
.action-btn.delete-icon:hover {
  color: #c00;
  border-color: #c00;
}
.action-btn:disabled {
  background-color: #f2f2f2;
}
.action-btn:disabled.edit-icon:hover {
  color: #ccc;
  border-color: #ccc;
}
.action-btn:disabled.delete-icon:hover {
  color: #ccc;
  border-color: #ccc;
}
.action-btn:last-child {
  margin-right: 0;
}
/*** Common styles ***/
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mb-2 {
  margin-bottom: 20px;
}
.ml-1 {
  margin-left: 10px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.hide {
  display: none;
}
.required-field h5.form-label:before {
  content: "*";
  color: #c00;
  margin-right: 5px;
}
/* Form Customization */
.material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: #666666;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
}
.material-checkbox input:focus {
  outline: none !important;
}
.css-14ojxsb-control {
  background-color: #ebebe4 !important;
  border: 1px solid #ccc;
}
/* Input */
.material-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}
/* Span */
.material-checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
/* Box */
.material-checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 9px 3px 0px;
  border: solid 1px;
  /* Safari */
  border-color: #adb5bd;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}
/* Checkmark */
.material-checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 11px;
  height: 6px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}
/* Checked, Indeterminate */
.material-checkbox > input:checked,
.material-checkbox > input:indeterminate {
  background-color: #304dc5;
}
.material-checkbox > input:checked + span::before,
.material-checkbox > input:indeterminate + span::before {
  border-color: #304dc5;
  background-color: #304dc5;
}
.material-checkbox > input:checked + span::after,
.material-checkbox > input:indeterminate + span::after {
  border-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
}
.material-checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}
/* Hover, Focus */
.material-checkbox:hover > input {
  opacity: 0.04;
}
.material-checkbox > input:focus {
  opacity: 0.12;
}
.material-checkbox:hover > input:focus {
  opacity: 0.16;
}
/* Active */
.material-checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}
.material-checkbox > input:active + span::before {
  border-color: #304dc5;
}
.material-checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
}
/* Disabled */
.material-checkbox > input:disabled {
  opacity: 0;
}
.material-checkbox > input:disabled + span {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  cursor: initial;
}
.material-checkbox > input:disabled + span::before {
  border-color: currentColor;
}
.material-checkbox > input:checked:disabled + span::before,
.material-checkbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
}
/* Radio Button */
.material-radio {
  z-index: 0;
  position: relative;
  display: inline-block;
  margin-right: 10px;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  font-size: 14px;
  line-height: 25px;
  font-weight: normal;
}
/* Input */
.material-radio > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -9px;
  top: -10px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}
.material-radio > input:focus {
  outline: none !important;
}
/* Span */
.material-radio > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
/* Circle */
.material-radio > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 2px 10px 2px 0;
  border: solid 1px;
  /* Safari */
  border-color: #adb5bd;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  vertical-align: top;
  transition: border-color 0.2s;
}
/* Check */
.material-radio > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: -1px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #304dc5;
  transform: translate(5px, 5px) scale(0);
  transition: transform 0.2s;
}
/* Checked */
.material-radio > input:checked {
  background-color: #304dc5;
}
.material-radio > input:checked + span::before {
  border-color: #304dc5;
}
.material-radio > input:checked + span::after {
  transform: translate(5px, 5px) scale(1);
}
/* Hover, Focus */
.pure-material-radio:hover > input {
  opacity: 0.04;
}
.material-radio > input:focus {
  opacity: 0.12;
}
.pure-material-radio:hover > input:focus {
  opacity: 0.16;
}
/* Active */
.material-radio > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}
.material-radio > input:active + span::before {
  border-color: #304dc5;
}
/* Disabled */
.material-radio > input:disabled {
  opacity: 0;
}
.material-radio > input:disabled + span {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  cursor: initial;
}
.material-radio > input:disabled + span::before {
  border-color: currentColor;
}
.material-radio > input:disabled + span::after {
  background-color: currentColor;
}
/* Changing styles to make it looks like Material */
#app .nav-pills > li.active > a,
#app .pagination > .active > a {
  background-color: #2840a5;
}
#app .pagination > .active > a,
#app .pagination > li > a {
  border: 1px solid #2840a5;
}
#app .table > thead > tr > th {
  border-bottom-width: 1px;
  outline: none;
}
.btn.btn-default {
  color: #1976d2;
  border: 1px solid #1976d2;
}
.btn.btn-default:hover,
.btn.btn-default:focus {
  color: #1976d2;
  background-color: rgba(25, 118, 210, 0.04);
  border: 1px solid #1976d2;
}
.btn.btn-primary {
  background-color: #2840a5;
}
.btn.btn-primary:hover,
.btn.btn-primary:focus {
  background-color: #1F3280;
}
#pageDropDown {
  color: #1F3280;
  border-color: #1F3280;
}
#pageDropDown:hover {
  background-color: #fff;
}
.nav-pills li {
  margin-right: 10px;
}
.nav-pills li.active a {
  border: 1px solid #2840a5;
  border-radius: 4px;
}
.nav-pills li:not(.active) #tabs-tab-batches,
.nav-pills li:not(.active) #tabs-tab-recipientGroups,
.nav-pills li:not(.active) #tabs-tab-responseReports {
  border: 1px solid #1976d2;
  border-radius: 4px;
}
.nav-pills li:not(.active) #tabs-tab-batches:hover,
.nav-pills li:not(.active) #tabs-tab-recipientGroups:hover,
.nav-pills li:not(.active) #tabs-tab-responseReports:hover {
  border-color: #2840a5;
  color: #2840a5;
  background-color: rgba(25, 118, 210, 0.04) !important;
}
.loader-absolute {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
.app-version {
  position: fixed;
  right: 10px;
  bottom: 0;
  color: #ccc;
  background-color: #fff;
}
.successes {
  color: green;
}
.failed {
  color: #c00;
}
.react-bootstrap-table .filter.date-filter label {
  width: calc(100% - 85px);
}
.react-bootstrap-table .filter.date-filter label:first-child {
  width: 70px;
  margin-right: 10px;
}
/* Employee Alerts */
.employee_alerts .filter {
  position: relative;
}
.employee_alerts .filter input {
  width: 100%;
  padding-right: 25px;
}
.employee_alerts .filter input[readonly] {
  background-color: #fff;
}
.employee_alerts .filter .icon-button {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 10px;
}
/* MASS NOTIFICATIONS */
.body-content .header-with-tabs {
  margin: -20px -90px auto -90px;
  padding: 0px 45px;
  box-shadow: 0 0 11px 5px rgba(0, 0, 0, 0.2);
}
.body-content .header-with-tabs h2 {
  font-size: 28px;
}
.body-content .header-with-tabs .mobile-menu-wrap {
  display: none;
}
@media all and (max-width: 900px) {
  .body-content .header-with-tabs {
    min-height: 60px;
  }
  .body-content .header-with-tabs .mobile-menu-wrap {
    display: inline-block;
    position: absolute;
    right: 20px;
  }
}
@media all and (max-width: 900px) {
  .body-content.container-fluid {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.mn-menu {
  margin-left: 40px;
  transition: all 0.3s;
}
.mn-menu.nav > li {
  display: inline-block;
  font-weight: bold;
}
.mn-menu.nav > li a {
  padding: 25px 15px;
  color: #777;
}
.mn-menu.nav > li.active:after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  background-color: #304dc5;
}
.mn-menu.nav > li.active a {
  color: #304dc5;
}
@media all and (max-width: 900px) {
  .mn-menu {
    display: none;
    position: absolute;
    width: 97%;
    margin-left: 0;
    left: 10px;
    right: 10px;
    top: 40px;
    background-color: #fff;
    z-index: 100;
    box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.5);
  }
  .mn-menu.nav > li {
    display: block;
    width: 100%;
  }
}
.btn.mn-btn {
  background-color: #66d9e8;
  color: #000;
  border-radius: 20px;
  padding: 10px 15px;
  border: none;
}
.btn.mn-btn:hover,
.btn.mn-btn:focus,
.btn.mn-btn:active {
  outline: none !important;
  background-color: #3ecfe2 !important;
  color: #000 !important;
}
.mass_notifications {
  margin-bottom: 30px;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.mass_notifications .add-new-recipient {
  display: inline-block;
  font-weight: bold;
  color: #304dc5;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
}
.mass_notifications .new-recipient-wrap {
  margin-top: 30px;
  border: 1px solid #d3d3d3;
  position: relative;
}
.mass_notifications .new-recipient-wrap .new-group-fields {
  padding-left: 0;
  margin-bottom: 0;
}
.mass_notifications .new-recipient-wrap .new-group-fields input {
  outline: none;
  border: 1px solid transparent;
  font-size: 18px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.mass_notifications .new-recipient-wrap .new-group-fields input:focus {
  border: 1px solid #ccc;
  background: #f8f8f8;
}
.mass_notifications .new-recipient-wrap .new-group-fields .col-xs-12 {
  padding-left: 0;
}
.mass_notifications .new-recipient-wrap .new-list-table-wrap {
  padding: 0;
}
.mass_notifications .new-recipient-wrap .new-list-table-wrap .MuiPaper-elevation2 {
  border: none;
}
.mass_notifications .new-recipient-wrap .new-list-table-wrap .MuiTableCell-head {
  border-top: 1px solid #e0e0e0;
}
.mass_notifications .new-recipient-wrap .MuiTableRow-root[mode=update] td:nth-child(3),
.mass_notifications .new-recipient-wrap .MuiTableRow-root[mode=add] td:nth-child(3) {
  position: relative;
}
.mass_notifications .new-recipient-wrap .MuiTableRow-root[mode=update] td:nth-child(3):before,
.mass_notifications .new-recipient-wrap .MuiTableRow-root[mode=add] td:nth-child(3):before {
  content: "+1";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.mass_notifications .new-recipient-wrap .MuiTableRow-root[mode=update] td:nth-child(3) input,
.mass_notifications .new-recipient-wrap .MuiTableRow-root[mode=add] td:nth-child(3) input {
  padding-left: 10px;
}
.mass_notifications .table-header-class {
  border-top: 1px solid #d3d3d3;
}
.mass_notifications .new-recipient-actions {
  margin-top: 30px;
}
.mass_notifications .new-recipient-actions .btn {
  padding: 7px 25px;
  font-weight: bold;
  font-size: 16px;
}
.mass_notifications .new-recipient-actions .btn-primary {
  color: #fff;
  border: 1px solid #304dc5;
}
.mass_notifications .react-bs-table-no-data {
  text-align: center;
  height: 200px;
  font-size: 22px;
}
.mass_notifications .editable-cell {
  position: relative;
  height: 30px;
}
.mass_notifications .editable-cell:after {
  content: 'Click to edit';
  position: absolute;
  color: #ccc;
  top: calc(50% - 8px);
  width: 100%;
  height: 100%;
}
.mass_notifications .editable-cell:first-child:after {
  content: 'Enter Recipient Name...';
}
.mass_notifications .editable-cell:nth-child(2):after {
  content: 'Enter Email...';
}
.mass_notifications .editable-cell:nth-child(3):after {
  content: 'Enter SMS...';
}
.mass_notifications .react-bootstrap-table-pagination-total {
  position: absolute;
  right: -90%;
}
.mass_notifications .react-bootstrap-table-pagination {
  margin: 0;
  border-top: 1px solid #ccc;
}
.mass_notifications .new-recipient-btn {
  position: absolute;
  bottom: 10px;
  left: 20px;
  border-radius: 5px;
}
.mass_notifications .new-recipient-btn:hover {
  background-color: #f3f5fc;
}
.mass_notifications .mn-distribution-list-table {
  margin-bottom: 0;
}
.mass_notifications .mn-distribution-list-table th,
.mass_notifications .mn-distribution-list-table td {
  padding: 8px 30px;
}
.mass_notifications .edit-campaign-btn-wrap {
  margin-top: 20px;
  margin-bottom: 40px;
  padding-right: 40px;
  text-align: right;
}
.mass_notifications .edit-campaign-btn-wrap .btn {
  min-width: 120px;
}
.mass_notifications .edit-campaign-btn-wrap .btn-primary {
  color: #fff;
  border: 1px solid #304dc5;
}
.mass_notifications .datepicker-wrap {
  margin-top: 30px;
  margin-bottom: 20px;
}
.mass_notifications .datepicker-wrap .material-checkbox {
  font-weight: bold;
  color: #333;
}
.mass_notifications .datepicker-wrap .material-checkbox span {
  line-height: 27px;
}
.mass_notifications .datepicker-wrap .react-bootstrap-daterangepicker-container {
  width: 100%;
}
.mass_notifications .helper-wrap {
  float: left;
  width: 100%;
  padding-left: 60px;
  text-align: left;
  position: relative;
  margin-top: -15px;
}
.mass_notifications .error-field {
  border: 1px solid #c00 !important;
}
.mass_notifications .error-field:focus {
  outline: none;
}
.mass_notifications .error-message {
  color: #c00;
}
.mass_notifications .fields-underline {
  border-color: #ccc;
}
.mass_notifications .response-desired-wrap {
  padding-left: 30px;
}
.mass_notifications .response-desired-wrap label {
  font-weight: bold;
  line-height: 27px;
}
.mass_notifications .response-message-input {
  height: 112px;
}
.mass_notifications .response-choices {
  padding-left: 45px;
}
.mass_notifications .response-choices h5 {
  font-weight: bold;
}
.mass_notifications .table-wrap {
  margin-top: 15px;
  padding: 0 40px;
}
.mass_notifications .MuiPaper-elevation2 {
  box-shadow: none;
  border: 1px solid #d8d8dc;
}
.mass_notifications .MuiTableHead-root th {
  background: #f5f6fa;
}
.mass_notifications .MuiTableCell-root {
  font-size: 1.4rem;
  font-weight: bold;
}
.mass_notifications .MuiToolbar-regular {
  position: absolute;
  right: 0;
  top: -60px;
}
.mass_notifications .MuiToolbar-regular div > div > div {
  display: flex;
}
.mass_notifications .MuiToolbar-regular div + div span:nth-child(2) {
  display: flex;
}
.mass_notifications .MuiToolbar-regular div + div span:nth-child(2) button:nth-child(2) {
  padding-bottom: 17px;
}
.mass_notifications .MuiTablePagination-toolbar {
  width: 50%;
  float: right;
  position: relative;
  right: auto;
  top: auto;
}
.mass_notifications .MuiTableCell-footer {
  z-index: 10;
}
.mass_notifications .MuiTableCell-footer .MuiTypography-caption {
  font-size: 14px;
}
.mass_notifications .MuiPaper-root {
  font-size: 14px !important;
}
.mass_notifications [class*="MTableToolbar-actions-"] .MuiIconButton-label svg {
  width: 2.5rem;
  height: 2.5rem;
}
.mass_notifications [class*="MTableToolbar-actions-"] div > div span + span {
  display: flex;
}
.mass_notifications [class*="MTableToolbar-actions-"] div > div span + span label {
  margin-bottom: 0;
}
.mass_notifications .MuiSvgIcon-root {
  font-size: 1.6em;
}
.mass_notifications .material-table-row td:last-child button {
  display: none;
}
.mass_notifications .material-table-row td:last-child > div {
  justify-content: flex-end;
}
.mass_notifications .material-table-row:hover {
  background-color: #f8f8f8;
}
.mass_notifications .material-table-row:hover td:last-child button {
  display: flex;
}
.mass_notifications .material-table-row:hover td:last-child button:nth-child(3) {
  display: none;
}
.mass_notifications .material-table-row.cell-actions-Draft:hover td:last-child button:last-child,
.mass_notifications .material-table-row.cell-actions-Scheduled:hover td:last-child button:last-child {
  display: none;
}
.mass_notifications .cell-actions-Draft:hover td:last-child button:first-child {
  display: none;
}
.mass_notifications .cell-actions-Sent:hover td:last-child button:first-child,
.mass_notifications .cell-actions-Sent:hover td:last-child button:nth-child(2),
.mass_notifications .cell-actions-Sent:hover td:last-child button:nth-child(3),
.mass_notifications .cell-actions-Sent:hover td:last-child button:nth-child(5) {
  display: none;
}
.mass_notifications .cell-actions-Sent:hover td:last-child button:nth-child(7) {
  display: flex;
}
.mass_notifications .icon-wrap svg {
  margin-bottom: -2px;
  font-size: 16px;
}
.mass_notifications .MuiTableCell-root .daterangepicker-container .form-control {
  height: 21px;
  padding-left: 30px;
  border: none;
  box-shadow: none;
  border-bottom: 1.5px solid #bfbfbf;
  border-bottom-style: inset;
  border-radius: 0;
}
.mass_notifications .MuiTableCell-root .daterangepicker-container .MuiSvgIcon-root {
  position: absolute;
  top: -2px;
  left: 0px;
  color: #ccc;
}
.mass_notifications .daterangepicker-container {
  position: relative;
}
.mass_notifications .daterangepicker-container .form-control {
  height: 38px;
}
.mass_notifications .daterangepicker-container .form-control[readonly] {
  background: transparent;
}
.mass_notifications .daterangepicker-container .MuiSvgIcon-root {
  position: absolute;
  top: 7px;
  right: 10px;
  color: #ccc;
  z-index: -1;
}
.mass_notifications tr.MuiTableRow-root:empty {
  display: none;
}
.mass_notifications .MuiFormControl-root .MuiInputBase-root {
  padding-bottom: 3px;
}
.mass_notifications .MuiFormControl-root .MuiInputBase-input {
  font-size: 14px;
}
.mass_notifications .MuiFormControl-root .MuiSelect-select {
  font-size: 12px;
  padding: 2px 24px 2px 10px;
}
.mass_notifications .MuiFormControl-root .MuiSelect-select:focus {
  background-color: transparent;
}
.mass_notifications .error-wrap {
  color: #c00;
  margin-bottom: 10px;
  font-size: 12px;
}
.mass_notifications .select-error-field .react-select__control {
  border-color: #c00;
  box-shadow: none;
}
.mass_notifications .MuiFormHelperText-root.Mui-error {
  font-size: 11px;
}
.mass_notifications input::-webkit-outer-spin-button,
.mass_notifications input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mass_notifications input[type=number] {
  -moz-appearance: textfield;
}
.mass_notifications .file-import {
  font-size: 0;
}
.mass_notifications .file-import .MuiSvgIcon-root {
  font-size: 24px !important;
  cursor: pointer;
}
.mass_notifications .file-import input[type=file] {
  display: none;
}
.mass_notifications div.status-wrap {
  display: inline-block;
}
.mass_notifications div.status-wrap::first-letter {
  text-transform: uppercase;
}
.mass_notifications tr.not-valid-cell-data {
  background-color: #ffc9c9;
}
.mass_notifications tr.not-valid-cell-number td:nth-child(3) {
  background-color: #ffc9c9;
}
@media all and (max-width: 900px) {
  .mass_notifications .container-fluid {
    padding: 0 !important;
  }
}
.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 12px !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1F3280 !important;
}
.MTableToolbar-formControlLabel-11 {
  font-size: 13px !important;
}
.scrollTable {
  overflow: auto;
  max-height: 760px;
  overflow-x: hidden;
}
.scrollTable th {
  position: sticky;
  top: -1px;
  background: #f2f2f2;
}
.daterangepicker .calendar-table td.today {
  background-color: #f2f2f2;
}
.daterangepicker .calendar-table td.active {
  background-color: #304dc5;
}
.daterangepicker .calendar-table td.active:hover {
  background-color: #2a43ac;
}
.MuiMenu-list li .MuiListItemText-root .MuiTypography-root {
  font-size: 12px !important;
}
/* Filter Select Component (MN) */
.filter-select-wrap {
  position: relative;
}
.filter-select-wrap .filter-select-input {
  padding: 5px 10px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.filter-select-wrap .filter-select-input:focus {
  border: none transparent;
}
.filter-select-wrap .filter-select-list {
  position: absolute;
  width: 100%;
  padding: 20px 10px 10px 20px;
  background-color: #fff;
  box-shadow: 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
}
.filter-select-wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.filter-select-btn-wrap {
  display: flex;
  justify-content: space-between;
}
.filter-select-btn-wrap .btn-primary {
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #1F3280;
}
.filter-select-btn-wrap .btn-primary:hover {
  color: #ffffff;
}
