@import "./theme.less";
.select {
    margin-top: 50px;
}

.form-label {
    font-weight: bold;
    text-align: left;
    vertical-align: middle;
}

.group {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.group.auth {
    display: block;
}

.right-block {
    max-width: 100%;
    float: right;
}

.selected-list {
    display: flex;
    flex-direction: row;
}

.selected-list.right {
    justify-content: flex-end;
}

.list-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.selected-text {
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

table {
    table-layout: fixed;
    word-wrap: break-word;
}

.project-desc {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashed-block{
        min-height: 300px;
        margin-bottom: 15px;
        padding: 20px;
        border-width: 2px;
        border-radius: 1px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #5d5858;
        outline: none;
        transition: border .24s ease-in-out
}